exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-wp-post-slug-js": () => import("./../../../src/pages/blog/{wpPost.slug}.js" /* webpackChunkName: "component---src-pages-blog-wp-post-slug-js" */),
  "component---src-pages-food-js": () => import("./../../../src/pages/food.js" /* webpackChunkName: "component---src-pages-food-js" */),
  "component---src-pages-food-wp-food-slug-js": () => import("./../../../src/pages/food/{wpFood.slug}.js" /* webpackChunkName: "component---src-pages-food-wp-food-slug-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

